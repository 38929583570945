import React, { useEffect, useState } from "react";
import "./Profile.css";
import {
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import { editUserProfile, getSpotifyRegisterURI } from "../../../services/Auth";
import SpotifyLinkButton from "../../SpotifyButtons/SpotifyLinkButton/SpotifyLinkButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import EditProfileRequest from "../../../models/EditProfileRequest";

function Profile() {
  const [spotifyURI, setSpotifyURI] = useState(null);
  const { user, loading, refreshUser } = useAuth();
  const [profileRequest, setProfileRequest] = useState(null); // Editable name field
  const [saving, setSaving] = useState(false); // For save button state
  const [error, setError] = useState(null); // Error state for save operation

  const navigate = useNavigate();

  useEffect(() => {
    getSpotifyRegisterURI()
      .then((data) => {
        setSpotifyURI(data);
      })
      .catch((error) => {
        console.error("Failed to fetch Spotify URI:", error);
        navigate("/error"); // Optional, to navigate to an error page
      });
  }, [navigate]);

  useEffect(() => {
    if (!loading) {
      setProfileRequest(new EditProfileRequest(user?.name));
    }
  }, [loading, user]);

  const onSpotifyLinkClicked = () => {
    if (spotifyURI) window.location.href = spotifyURI;
  };

  const handleSave = async () => {
    setSaving(true);
    setError(null); // Clear previous error
    try {
      await editUserProfile(profileRequest);
      refreshUser();
    } catch (err) {
      setError("Failed to save profile. Please try again.");
    } finally {
      setSaving(false);
    }
  };
  if (loading || !spotifyURI || !profileRequest) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    spotifyURI != null && (
      <Box
        className="profile__container"
        sx={{
          margin: "auto",
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card sx={{ backgroundColor: "white", width: "70%" }} elevation={4}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Edit Profile
            </Typography>

            {/* Editable Name Field */}
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={profileRequest.name}
              onChange={(e) =>
                setProfileRequest((prev) => ({ ...prev, name: e.target.value }))
              }
            />

            {/* Save Button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}
              disabled={saving}
              sx={{ mt: 2 }}
            >
              {saving ? "Saving..." : "Save"}
            </Button>

            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
          </CardContent>
        </Card>
        <Paper
          elevation={4}
          sx={{
            mt: 4,
            p: 1.5,
            width: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "white",
          }}
        >
          <Typography sx={{ mr: 2 }} variant="h4">
            Relink Spotify
          </Typography>
          <SpotifyLinkButton onClick={onSpotifyLinkClicked}></SpotifyLinkButton>
        </Paper>
      </Box>
    )
  );
}

export default Profile;
