class EmotionColor {
    constructor(primary, secondary, defaultColorOrder) {
      this.primary = primary;
      this.secondary = secondary;
      this.defaultColorOrder = defaultColorOrder;
    }
  
    // Static method to convert JSON data to an EmotionColor instance
    static fromJSON(data) {
      return new EmotionColor(
        data.primary,
        data.secondary,
        data.defaultColorOrder
      );
    }
  }
  
  export default EmotionColor;
  