import React, { createContext, useState, useContext, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Function to display a notification
  //type e.g. : 'error', 'info', 'warning' 'success'
  //position e.g. : { vertical: "bottom", horizontal: "center" }
  const addNotification = useCallback(
    ({ type, content, id, duration, position }) => {
      setNotifications((prev) => [
        ...prev,
        { type, content, id, duration, position },
      ]);
    },
    [setNotifications]
  );

  // Function to close a notification by ID
  const closeNotification = useCallback(
    (id) => {
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== id)
      );
    },
    [setNotifications]
  );

  return (
    <NotificationContext.Provider
      value={{ addNotification, closeNotification }}
    >
      {children}
      {/* Render Snackbar notifications */}
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open
          autoHideDuration={notification.duration || 3000} // Default to 3000ms
          onClose={() => closeNotification(notification.id)} 
          anchorOrigin={
            notification.position || { vertical: "bottom", horizontal: "right" }
          }
        >
          <Alert
            onClose={() => closeNotification(notification.id)}
            severity={notification.type}
            sx={{ width: "100%" }}
          >
            {notification.content}
          </Alert>
        </Snackbar>
      ))}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the NotificationContext
export const useNotification = () => {
  return useContext(NotificationContext);
};
