class PlaylistData {
    constructor(id, name, description, isPublic, isCollaborative, ownerId, trackCount) {
      this.id = id; // The playlist ID
      this.name = name; // The name of the playlist
      this.description = description; // The description of the playlist
      this.isPublic = isPublic; // Whether the playlist is public
      this.isCollaborative = isCollaborative; // Whether the playlist is collaborative
      this.ownerId = ownerId; // The Spotify ID of the owner
      this.trackCount = trackCount; // The number of tracks in the playlist
    }
  
    // Static method to convert JSON data to a PlaylistData instance
    static fromJSON(data) {
      return new PlaylistData(
        data.id,
        data.name,
        data.description,
        data.public,
        data.collaborative,
        data.ownerId,
        data.trackCount
      );
    }
  }
  
  export default PlaylistData;
  