import React from "react";
import { List, Box } from "@mui/material";
import RecommendationTrackItem from "../RecommendationTrackItem/RecommendationTrackItem";

const RecommendationTracksList = ({ tracks }) => {
  return (
    <Box>
      <List>
        {tracks.map((trackDto, index) => (
          <RecommendationTrackItem key={index} trackDto={trackDto} />
        ))}
      </List>
    </Box>
  );
};

export default RecommendationTracksList;
