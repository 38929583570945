import React from "react";
import { List, Box, Checkbox } from "@mui/material";
import RecommendationTrackItem from "../RecommendationTrackItem/RecommendationTrackItem";

const RecommendationTracksSelectList = ({
  tracks,
  selectedItems = [],
  onSelectChange = () => {},
}) => {
  const handleSelectionChange = (trackDto) => {
    const isSelected = selectedItems.some(
      (item) => item.trackData.id === trackDto.trackData.id
    );
    const updatedSelection = isSelected
      ? selectedItems.filter(
          (item) => item.trackData.id !== trackDto.trackData.id
        ) // Remove from selection
      : [...selectedItems, trackDto]; // Add to selection

    onSelectChange(updatedSelection);
  };

  const handleTrackClick = (event, trackDto) => {
    // Prevent toggling when the click originates from a button
    if (event.target.closest("button")) {
      return;
    }

    event.stopPropagation(); // Prevent event propagation to parent elements
    handleSelectionChange(trackDto);
  };

  return (
    tracks && (
      <Box>
        <List>
          {tracks.map((trackDto, index) => {
            const isSelected = selectedItems.some(
              (item) => item.trackData.id === trackDto.trackData.id
            );

            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(event) => handleTrackClick(event, trackDto)} // Handle click on the entire item
              >
                <Checkbox
                  id={`checkbox-${trackDto.trackData.id}`} // Unique id for each checkbox
                  checked={isSelected}
                />
                <RecommendationTrackItem trackDto={trackDto} />
              </Box>
            );
          })}
        </List>
      </Box>
    )
  );
};

export default RecommendationTracksSelectList;
