import TrackData from './TrackData';

class RecommendationResultTrackDto {
    constructor(liked, trackData) {
        this.liked = liked;
        this.trackData = trackData;
    }

    static fromJSON(data) {
        return new RecommendationResultTrackDto(
            data.liked,
            TrackData.fromJSON(data.trackData)
        );
    }
}

export default RecommendationResultTrackDto;
