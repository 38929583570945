import React, { useRef, useEffect, useState } from "react";
import {
  ScatterChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Scatter,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
} from "recharts";
import EmotionTooltip from "../CustomTooltips/EmotionTooltip";
import ClickedPointTooltip from "../CustomTooltips/ClickedPointTooltip";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import KeddyEmotionPoint from "./KeddyEmotionPoint";

const EmotionChart = ({ emotions, selectedPoint, onPointClicked }) => {
  const chartRef = useRef(null); // Reference to the chart container
  const [pointSize, setPointSize] = useState(16); // State for point size

  useEffect(() => {
    const updatePointSize = () => {
      if (chartRef.current) {
        const { width, height } = chartRef.current.getBoundingClientRect();
        // Dynamically calculate the point size based on chart dimensions
        setPointSize(Math.min(width, height) / 25);
      }
    };

    // Update point size on initial render
    updatePointSize();

    // Add a resize event listener to handle window resizing
    window.addEventListener("resize", updatePointSize);
    return () => window.removeEventListener("resize", updatePointSize);
  }, []);

  const handlePointClick = (e) => {
    if (e?.xValue != null && e?.yValue != null) {
      onPointClicked &&
        onPointClicked({valence: e.xValue, arousal: e.yValue });
    }
  };

  const renderCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const hoveredPoint = payload[0].payload;

      // Check the isClickedPoint property
      if (hoveredPoint.isClickedPoint) {
        return <ClickedPointTooltip active={active} payload={payload} />;
      }

      return <EmotionTooltip active={active} payload={payload} />;
    }
    return null;
  };

  return (
    emotions && (
      <div
        ref={chartRef} // Attach the ref to the container
        style={{ width: "100%", paddingBottom: "100%", position: "relative" }}
      >
        <ResponsiveContainer
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, right: 31 }}
        >
          <ScatterChart
            margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
            onClick={handlePointClick}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              dataKey="valence"
              domain={[-1, 1]}
              label={{
                value: "Valence ->",
                position: "outsideBottom",
                offset: 0, // Adjust label position if necessary
                style: { textAnchor: "middle", fontSize: "12px", fill: "#000" },
              }}
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <YAxis
              type="number"
              dataKey="arousal"
              domain={[-1, 1]}
              label={{
                value: "Arousal ->",
                position: "insideLeft",
                angle: -90,
                offset: 45, // Adjust label position if necessary
                style: { textAnchor: "middle", fontSize: "12px", fill: "#000" },
              }}
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <ReferenceLine x={0} stroke="black" />
            <ReferenceLine y={0} stroke="black" />
            {/* Unified Tooltip */}
            <Tooltip
              content={renderCustomTooltip}
              cursor={{ strokeDasharray: "3 3" }}
            />
            {/* Display the clicked point */}
            {selectedPoint && (
              <Scatter
                name="Clicked Point"
                data={[{ ...selectedPoint, isClickedPoint: true }]} // Add isClickedPoint
                shape={(props) => {
                  const size = Math.max(
                    Math.min(pointSize, props.cx * 2, props.cy * 2),
                    13
                  ); // Ensure size fits within boundaries
                  const adjustedCx = Math.max(props.cx, size / 2); // Prevent overflow on the left
                  const adjustedCy = Math.max(props.cy, size / 2); // Prevent overflow on the top

                  return (
                    <KeddyEmotionPoint
                      cx={adjustedCx} // Pass x position (cx) for KeddyEmotionPoint
                      cy={adjustedCy} // Pass y position (cy) for KeddyEmotionPoint
                      primary={selectedPoint.primary} // Ensure these props are passed correctly
                      secondary={selectedPoint.secondary}
                      size={size} // Set the size dynamically
                    />
                  );
                }}
              />
            )}
            {/* Display emotion data */}
            <Scatter
              name="Emotions"
              data={emotions}
              shape={(props) => {
                const size = Math.max(
                  Math.min(pointSize, props.cx * 2, props.cy * 2),
                  13
                ); // Ensure size fits within boundaries
                const adjustedCx = Math.max(props.cx - size / 2, 0); // Prevent overflow on the left
                const adjustedCy = Math.max(props.cy - size / 2, 0); // Prevent overflow on the top

                return (
                  <svg
                    x={adjustedCx} // Constrain x position
                    y={adjustedCy} // Constrain y position
                    width={size} // Ensure the container is large enough
                    height={size}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <foreignObject width="100%" height="100%">
                      <RadioButtonCheckedIcon
                        style={{
                          width: "100%",
                          height: "100%",
                          color: props.payload.color.primary,
                        }}
                      />
                    </foreignObject>
                  </svg>
                );
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    )
  );
};

export default EmotionChart;
