import React from 'react';
import './KeddyIcon.css';
import catImage from '../../assets/keddy_head_upscale_circle.png'
import { Typography } from '@mui/material';


function KeddyIcon() {
    return (
        <div className='keddy-icon__container'>
            <img
                src={catImage}
                alt="Cat"
                className="keddy-icon__cat-image"
            />
            <Typography variant="h2" component="h2" fontSize={15} color={'white'}>
                Keddy
            </Typography>
        </div>
    );
}

export default KeddyIcon;