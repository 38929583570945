import React from "react";
import Button from "@mui/material/Button";
import SpotifyIcon from "../SpotifyIcon";

const SpotifyOpenButton = ({ onClick }) => {
  return (
    <Button
      startIcon={<SpotifyIcon color="white" />}
      variant="contained"
      style={{ width: "auto", height: "auto" }}
      onClick={onClick}
    ></Button>
  );
};

export default SpotifyOpenButton;
