import React, { createContext, useContext, useState } from 'react';

// Create context
const PlayerContext = createContext();

export const usePlayer = () => useContext(PlayerContext);

export const PlayerProvider = ({ children }) => {
    const [currentTrack, setCurrentTrack] = useState(null);

    const playTrack = (trackId, audio) => {
        if (currentTrack && currentTrack.audio) {
            currentTrack.audio.pause();
        }
        // Set the new track as the current track
        setCurrentTrack({ trackId, audio });
        audio.play();
    };

    const stopTrack = () => {
        if (currentTrack && currentTrack.audio) {
            currentTrack.audio.pause();
        }
        setCurrentTrack(null);
    };

    return (
        <PlayerContext.Provider value={{ currentTrack, playTrack, stopTrack }}>
            {children}
        </PlayerContext.Provider>
    );
};
