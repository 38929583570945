import "./LinkSpotify.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, LinearProgress } from "@mui/material";
import KeddyHeader from "../../KeddyHeader/KeddyHeader";
import {
  getSpotifyRegisterURI,
  linkSpotifyUserProperties,
} from "../../../services/Auth";
import SpotifyLinkButton from "../../SpotifyButtons/SpotifyLinkButton/SpotifyLinkButton";

function LinkSpotify() {
  const navigate = useNavigate();
  const location = useLocation();
  const [spotifyURI, setSpotifyURI] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const state = queryParams.get("state");

  const onSpotifyLinkClicked = () => {
    if (spotifyURI) window.location.href = spotifyURI;
  };

  useEffect(() => {
    if (code && state) {
      // Execute the linking function with the code and state
      linkSpotifyUserProperties(code, state)
        .then((response) => {
          if (response.status === 200) {
            navigate("/");
          } else {
            const errorCode = response?.data?.code || "NO_ERROR_CODE_FROM_BE";
            navigate("/error", { search: `?code=${errorCode}` });
          }
        })
        .catch((err) => {
          console.error("Unexpected error occurred:", err);
          // Handle error and possibly navigate to an error page
          navigate("/error");
        });
    } else {
      getSpotifyRegisterURI()
        .then((data) => {
          setSpotifyURI(data);
        })
        .catch((error) => {
          console.error("Failed to fetch Spotify URI:", error);
          navigate("/error");
        });
    }
  }, [code, state, navigate]);

  return code && state ? (
    <div className="link-spotify__complete-link-container">
      <div className="link-spotify__complete-link-content">
        <div style={{ marginBottom: 35 }}>
          <KeddyHeader />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Linking your Spotify account</Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 35,
          }}
        >
          <LinearProgress
            style={{ width: "100%", height: "10px" }}
            color="inherit"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="link-spotify__link-spotify-container">
      <div className="link-spotify__link-spotify-content">
        <div style={{ marginBottom: 35 }}>
          <KeddyHeader />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            Let Keddy explore your music repertoire by linking your Spotify
            account:
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 35,
          }}
        >
          {spotifyURI && (
            <SpotifyLinkButton onClick={onSpotifyLinkClicked} />
          )}
        </div>
      </div>
    </div>
  );
}

export default LinkSpotify;
