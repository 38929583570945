import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  TextField,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import RecommendationTracksList from "../../../RecommendationTracksList/RecommendationTracksList";
import {
  getEmotions,
  getRecommendationDtoByResultId,
} from "../../../../services/Recommendations";
import EmotionChart from "../../../EmotionChart/EmotionChart";
import ExportSongsButton from "../../../ExportSongsButton/ExportSongsButton";
import ExportSongsModal from "../../../../popups/ExportSongsModal/ExportSongsModal";

function RecommendationResult() {
  const { resultId } = useParams();
  const [recommendations, setRecommendations] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [emotions, setEmotions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recommendations, emotions] = await Promise.all([
          getRecommendationDtoByResultId(resultId),
          getEmotions(),
        ]);
        setSelectedPoint({
          valence: recommendations.valence,
          arousal: recommendations.arousal,
          text: "You are here",
        });
        setRecommendations(recommendations);
        setEmotions(emotions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [resultId]);

  const onExportClicked = () => {
    setExportModalOpen(true); // Open the modal
  };

  return (
    <Container sx={{ mt: 4 }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : recommendations ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            pb: 3,
          }}
        >
          <Card
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              pt: 3,
              pb: 1,
              pl: 2,
              pr: 2,
              width: "85%",
            }}
          >
            <Typography variant="h4" gutterBottom>
              {recommendations.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 2, fontStyle: "italic", color: "gray" }}
            >
              {new Date(recommendations.timeCreated).toLocaleString("en-US", {
                dateStyle: "long",
                timeStyle: "short",
              })}
            </Typography>
            <TextField
              fullWidth
              label="Prompt"
              variant="outlined"
              multiline
              focused
              rows={3}
              value={
                recommendations.userPrompt ? recommendations.userPrompt : "N/A"
              }
              InputProps={{
                readOnly: true,
              }}
              sx={{ mb: 3 }}
            />
            <Typography
              variant="body2"
              sx={{ mb: 1, fontStyle: "italic", color: "gray" }}
            >
              Your location on Valence/Arousal Map:
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <EmotionChart
                emotions={emotions}
                selectedPoint={selectedPoint}
                onPointClicked={(value) =>
                  setSelectedPoint({ ...value, text: "You are here" })
                }
              />
            </Box>
          </Card>
          <ExportSongsButton onClick={onExportClicked} />
          <Box
            sx={{
              width: "85%",
            }}
          >
            <RecommendationTracksList tracks={recommendations?.tracks} />
          </Box>
          <ExportSongsModal
            open={exportModalOpen}
            onClose={() => setExportModalOpen(false)} // Close the modal
            recommendations={recommendations}
          />
        </Box>
      ) : (
        <Typography>No recommendation found for this ID</Typography>
      )}
    </Container>
  );
}

export default RecommendationResult;
