import axiosInstance from "./axiosInstance";
import Emotion from "../models/Emotion";
import EmotionTrackMapping from "../models/EmotionTrackMapping";
import RecommendationDto from "../models/RecommendationDto";
import RecommendationResult from "../models/RecommendationResult";
import PlaylistData from "../models/PlaylistData";

const getEmotions = async () => {
  try {
    const response = await axiosInstance.get(`/api/recommendations/emotions`);
    const emotions = response.data.map(Emotion.fromJSON);
    return emotions;
  } catch (error) {
    console.error("Error fetching emotions:", error);
    return null;
  }
};

const getEmotionTrackMappings = async (withAllEmotions = true) => {
  try {
    const response = await axiosInstance.get(
      `/api/recommendations/emotion-track-mappings`,
      {
        params: {
          withAllEmotions: withAllEmotions,
        },
      }
    );
    const emotionTrackMappingList = response.data.map(
      EmotionTrackMapping.fromJSON
    );
    return emotionTrackMappingList;
  } catch (error) {
    console.error("Error fetching emotionTrackMappingList:", error);
    return null;
  }
};

const getRecommendations = async (textPrompt, coordinate, vibeId) => {
  try {
    const requestBody = {
      prompt: textPrompt || null, // Include prompt if provided
      coordinate: coordinate || null, // Include coordinate if provided
      vibeId: vibeId || null, // Include vibeId if provided
    };

    const response = await axiosInstance.post(
      `/api/recommendations/recommend`,
      requestBody
    );

    if (response.status !== 200) {
      throw new Error(`Unexpected status code: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    throw error;
  }
};

const getRecommendationDtoByResultId = async (resultId) => {
  try {
    const response = await axiosInstance.get(
      `/api/recommendations/recommendation/${resultId}`
    );
    const recommendations = RecommendationDto.fromJSON(response.data);
    return recommendations;
  } catch (error) {
    console.error("Error fetching recommendation by result id:", error);
    return null;
  }
};

const getUserOwnedPlaylists = async () => {
  try {
    const response = await axiosInstance.get(`/api/playlists/all`);
    const playlists = response.data.map(PlaylistData.fromJSON); // Map response to PlaylistData model
    return playlists;
  } catch (error) {
    console.error("Error fetching user-owned playlists:", error);
    return [];
  }
};

// Function to create a new playlist and add tracks
const exportTracksCreatePlaylist = async (recommendationResultId, trackIds) => {
  try {
    if (!recommendationResultId || !trackIds || trackIds.length === 0) {
      throw new Error(
        "Invalid parameters for exportTracksCreatePlaylist request"
      );
    }

    const playlistCreateRequest = {
      recommendationResultId: recommendationResultId,
      tracks: trackIds,
    };

    const response = await axiosInstance.post(
      `/api/playlists/playlist`,
      playlistCreateRequest
    );

    if (response.status === 200) {
      return response.data; // Playlist ID
    } else {
      throw new Error("Failed to create playlist");
    }
  } catch (error) {
    throw error;
  }
};

// Function to add tracks to an existing playlist
const exportTracksExistingPlaylist = async (playlistId, trackIds) => {
  try {
    if (!playlistId || !trackIds || trackIds.length === 0) {
      throw new Error(
        "Invalid parameters for exportTracksExistingPlaylist request"
      );
    }

    const playlistEditRequest = {
      playlistId: playlistId,
      tracks: trackIds,
    };

    const response = await axiosInstance.put(
      `/api/playlists/playlist`,
      playlistEditRequest
    );

    if (response.status === 200) {
      return playlistId; // Playlist ID
    } else {
      throw new Error("Failed to add tracks to playlist");
    }
  } catch (error) {
    throw error;
  }
};

const getRecommendationResults = async () => {
  try {
    const response = await axiosInstance.get(`/api/recommendations/results`);
    const results = response.data.map(RecommendationResult.fromJSON);
    return results;
  } catch (error) {
    console.error("Error fetching recommendation results:", error);
    return null;
  }
};

export {
  getEmotions,
  getEmotionTrackMappings,
  getRecommendations,
  getRecommendationDtoByResultId,
  getRecommendationResults,
  getUserOwnedPlaylists,
  exportTracksCreatePlaylist,
  exportTracksExistingPlaylist,
};
