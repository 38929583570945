import "./Recommend.css";
import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRecommendations } from "../../../../services/Recommendations";

const Recommend = () => {
  const [textPrompt, setTextPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // For validation and submission error messages
  const [serverError, setServerError] = useState(""); // For server error messages
  const navigate = useNavigate();

  const handleTextPromptChange = (event) => {
    const value = event.target.value;

    // Validation: Check length and set error message
    if (value.length < 10) {
      setError("Text must be at least 10 characters.");
    } else if (value.length > 600) {
      setError("Text must not exceed 600 characters.");
    } else {
      setError(""); // Clear error if validation passes
    }

    setTextPrompt(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setServerError(""); // Clear any previous server error
    try {
      const resultId = await getRecommendations(textPrompt, null, null);
      if (resultId) {
        navigate(`/results/${resultId}`);
      }
    } catch (error) {
      setServerError(
        error.response?.data?.message ||
          "Failed to fetch recommendations. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", mt: 4 }}>
      {/* Text Field with validation */}
      <TextField
        label="How do you feel?"
        variant="outlined"
        placeholder="Write something that describes your current situation, feelings or anything that you have been through"
        multiline
        rows={4} // Allows for paragraph-like input
        value={textPrompt}
        onChange={handleTextPromptChange}
        fullWidth
        margin="normal"
        error={!!error} // Material UI shows error styling when this is true
        helperText={error} // Shows validation error below the field
        inputProps={{
          minLength: 10,
          maxLength: 600,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={
          loading ||
          !!error ||
          textPrompt.length < 10 ||
          textPrompt.length > 600
        }
        fullWidth
        sx={{ mt: 2 }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Get Recommendations"
        )}
      </Button>

      {/* Error Message Below Button */}
      {serverError && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {serverError}
        </Typography>
      )}
    </Box>
  );
};

export default Recommend;
