import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SpotifyOpenButton from "../SpotifyButtons/SpotifyOpenButton/SpotifyOpenButton";
import { usePlayer } from "../../context/PlayerProvider";

const RecommendationTrackItem = ({ trackDto }) => {
  const album = trackDto.trackData.album || {};
  const albumImages = Array.isArray(album.images) ? album.images : [];
  const albumImageUrl =
    albumImages.length > 0 ? albumImages[albumImages.length - 1].url : "";

  const [isLiked, setIsLiked] = useState(trackDto.liked || false); // Assuming `liked` is in the data
  const { currentTrack, playTrack, stopTrack } = usePlayer();
  const isPlaying = currentTrack?.trackId === trackDto.trackData.id;

  const audio = trackDto?.trackData?.previewUrl
    ? new Audio(trackDto.trackData.previewUrl)
    : null; // Audio preview URL

  const handlePlayPause = () => {
    if (isPlaying) {
      stopTrack();
    } else {
      playTrack(trackDto.trackData.id, audio);
    }
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
    // Send request to backend to toggle like status
    // Example:
    // await toggleLikeTrack(trackDto.id);
  };

  const openInSpotify = () => {
    window.open(trackDto.trackData.externalUrls.spotify, "_blank");
  };

  return (
    <Paper
      elevation={3}
      sx={(theme) => ({
        marginBottom: "5px",
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        height: "85px",
        paddingLeft: "5px",
        backgroundColor: theme.palette.primary.main,
      })}
    >
      <ListItem
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: "2px",
          borderRadius: "8px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          width: "100%",
        })}
      >
        {/* Album image */}
        {albumImageUrl && (
          <ListItemAvatar>
            <Avatar
              src={albumImageUrl}
              alt={`${album.name} album cover`}
              variant="square"
              sx={{ width: 60, height: 60, borderRadius: "8px" }}
            />
          </ListItemAvatar>
        )}

        {/* Track details */}
        <ListItemText
          sx={{ paddingLeft: "1rem", overflow: "hidden", whiteSpace: "nowrap" }}
          primary={
            <Typography
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.common.white,
                fontSize: "1rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              })}
            >
              {trackDto.trackData.name}
            </Typography>
          }
          secondary={
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.palette.background.default,
                fontSize: "0.8rem",
                fontStyle: "italic",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              })}
            >
              {trackDto.trackData.artists[0]?.name || "Unknown Artist"}
            </Typography>
          }
        />

        {/* Action buttons */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* Play button */}
          {audio && (
            <IconButton onClick={handlePlayPause} color="inherit">
              {isPlaying ? (
                <PauseIcon color="inherit" />
              ) : (
                <PlayArrowIcon color="inherit" />
              )}
            </IconButton>
          )}

          {/* Like button */}
          <IconButton onClick={handleLike} color="inherit">
            <FavoriteIcon color={isLiked ? "success" : "inherit"} />
          </IconButton>

          {/* Open in Spotify button */}
          <SpotifyOpenButton onClick={openInSpotify} />
        </div>
      </ListItem>
    </Paper>
  );
};

export default RecommendationTrackItem;
