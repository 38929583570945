import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import { getRecommendationResults } from "../../../../services/Recommendations";
import RecommendationResultsList from "../../../RecommendationResultsList/RecommendationResultsList";

const RecommendationResults = () => {
  const [recommendationResults, setRecommendationResults] = useState(null);

  useEffect(() => {
    getRecommendationResults().then((data) => {
      setRecommendationResults(data ? data : []);
    });
  }, []);

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ width: "100%" }}>
        {recommendationResults ? (
          <RecommendationResultsList
            recommendationResults={recommendationResults}
          />
        ) : (
          <CircularProgress></CircularProgress>
        )}
      </Box>
    </Container>
  );
};

export default RecommendationResults;
