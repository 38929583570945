import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

const EmotionTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const emotion = payload[0].payload;

    return (
      <Card
        elevation={3}
        sx={{
          background: `linear-gradient(to bottom, ${emotion.color.primary}, ${emotion.color.secondary})`,
          color: "#fff",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <CardContent>
          {/* Emotion Name */}
          <Typography variant="h6" gutterBottom>
            {emotion.name}
          </Typography>

          {/* Valence and Arousal */}
          <Typography variant="body2">
            Valence: <strong>{emotion.valence.toFixed(2)}</strong>
          </Typography>
          <Typography variant="body2">
            Arousal: <strong>{emotion.arousal.toFixed(2)}</strong>
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default EmotionTooltip;
