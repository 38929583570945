class TrackData {
    constructor(album, artists, availableMarkets, discNumber, durationMs, explicit, externalIds, externalUrls, href, id, isPlayable, name, popularity, previewUrl, trackNumber, type, uri) {
        this.album = album;
        this.artists = artists;
        this.availableMarkets = availableMarkets;  // Now treated as a simple array of strings
        this.discNumber = discNumber;
        this.durationMs = durationMs;
        this.explicit = explicit;
        this.externalIds = externalIds;
        this.externalUrls = externalUrls;
        this.href = href;
        this.id = id;
        this.isPlayable = isPlayable;
        this.name = name;
        this.popularity = popularity;
        this.previewUrl = previewUrl;
        this.trackNumber = trackNumber;
        this.type = type;
        this.uri = uri;
    }

    static fromJSON(data) {
        return new TrackData(
            data.album,
            data.artists,
            data.availableMarkets || [],  // Just using availableMarkets as an array of strings
            data.discNumber,
            data.durationMs,
            data.explicit,
            data.externalIds,
            data.externalUrls,
            data.href,
            data.id,
            data.isPlayable,
            data.name,
            data.popularity,
            data.previewUrl,
            data.trackNumber,
            data.type,
            data.uri
        );
    }
}

export default TrackData;
