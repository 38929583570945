import EmotionColor from './EmotionColor';

class Emotion {
  constructor(id, name, valence, arousal, coordinateArea, color) {
    this.id = id;
    this.name = name;
    this.valence = valence;
    this.arousal = arousal;
    this.coordinateArea = coordinateArea;
    this.color = color ? EmotionColor.fromJSON(color) : null; // Initialize EmotionColor if provided
  }

  // Static method to convert JSON data to an Emotion instance
  static fromJSON(data) {
    return new Emotion(
      data.id,
      data.name,
      data.valence,
      data.arousal,
      data.coordinateArea,
      data.color // Map color data directly
    );
  }
}

export default Emotion;
