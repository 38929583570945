import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import LoginPage from "./components/Pages/LoginPage/LoginPage";
import Error from "./components/Pages/Error/Error";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";

import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";

import "./App.css";
import Profile from "./components/Pages/Profile/Profile";
import RecommendationsRouter from "./components/Pages/Recomendations/RecommendationsRouter";
import Recommend from "./components/Pages/Recomendations/Recommend/Recommend";
import RecommendationResults from "./components/Pages/Recomendations/RecommendationResults/RecommendationResults";
import RecommendationResult from "./components/Pages/Recomendations/RecommendationResult/RecommendationResult";
import KeddyIcon from "./components/KeddyIcon/KeddyIcon";
import { AuthProvider } from "./context/AuthProvider";
import LinkSpotify from "./components/Pages/LinkSpotify/LinkSpotify";
import RecommendationResultsRouter from "./components/Pages/Recomendations/RecommendationResultsRouter";
import { PlayerProvider } from "./context/PlayerProvider";
import { NotificationProvider } from "./context/NotificationProvider";

function App() {
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme

  const location = useLocation();
  const [tabValue, setTabValue] = React.useState(false); // Set the initial value
  const [accountAnchorEl, setaccountAnchorEl] = React.useState(null);
  const accountPopupOpen = Boolean(accountAnchorEl);

  // Memoize the pageMappings object to avoid re-creating it on every render
  const pageMappings = useMemo(
    () => ({
      recommend: ["/recommendations/recommend", "/recommendations/manual"],
      recommendationResults: ["/results"],
      userRelated: ["/profile", "/settings"],
    }),
    []
  );
  const popupTabs = useMemo(() => ["userRelated"], []);

  const menuItemSelected = (value) => {
    switch (value) {
      case "settings":
        break;
      case "profile":
        navigate("/profile");
        break;
      case "logout":
        navigate("/login");
        break;
      default:
        break;
    }
    setaccountAnchorEl(null);
  };

  useEffect(() => {
    const matchedKey = Object.keys(pageMappings).find(
      (key) =>
        pageMappings[key].some((path) => location.pathname.startsWith(path)) // Check if any path matches
    );

    setTabValue(matchedKey || null); // Set tab value to the matched key or null if no match is found
  }, [location.pathname, pageMappings]);

  const handleChange = (event, newValue) => {
    if (!popupTabs.includes(newValue)) {
      setTabValue(newValue);
      // Navigate to the selected tab's route based on pageMappings
      if (pageMappings[newValue]) {
        if (newValue !== "userRelated") navigate(pageMappings[newValue][0]);
      } else {
        setTabValue(null);
        navigate("/error");
      }
    }
  };

  const handleTabClick = (clickedTabValue) => {
    // Renavigate if already selected
    if (tabValue === clickedTabValue) {
      navigate(pageMappings[clickedTabValue][0]);
    }
  };

  return (
    <div className="App">
      {tabValue && (
        <AppBar
          position="fixed"
          sx={{
            height: { xs: "48px", sm: "48px" }, // 56px on small screens (xs), 64px on larger screens (sm and up)
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <KeddyIcon></KeddyIcon>
          </div>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          />
          <Tabs
            sx={{ flexGrow: 1 }}
            value={tabValue}
            textColor="inherit"
            indicatorColor="secondary"
            onChange={handleChange}
          >
            <Tab
              label="Recommendations"
              onClick={() => handleTabClick("recommend")}
              value="recommend"
            />
            <Tab
              label="Recommendation Results"
              onClick={() => handleTabClick("recommendationResults")}
              value="recommendationResults"
            />
            <Tab
              id="account-related-tab"
              value="userRelated"
              icon={
                <PersonIcon
                  sx={{
                    color: "white",
                    width: "35px",
                    height: "35px",
                  }}
                />
              }
              onClick={(event) => {
                event.stopPropagation(); // Stop the event from propagating
                setaccountAnchorEl(event.currentTarget); // Open the popup
              }}
              sx={{
                marginLeft: "auto", // Push this tab to the right
                padding: "0px", // Remove default padding
                "& .MuiTab-iconWrapper": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            />
          </Tabs>

          <Menu
            id="account-menu"
            anchorEl={accountAnchorEl}
            open={accountPopupOpen}
            onClose={(value) => {
              setaccountAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "account-related-tab",
            }}
          >
            <MenuItem onClick={() => menuItemSelected("profile")}>
              <ListItemIcon>
                <PersonOutline fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem disabled onClick={() => menuItemSelected("settings")}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={() => menuItemSelected("logout")}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </AppBar>
      )}
      <Box sx={{ paddingTop: { xs: "48px", sm: "48px" } }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/link-spotify" element={<LinkSpotify />} />
          <Route path="/recommendations" element={<RecommendationsRouter />}>
            <Route path="" element={<Navigate to="recommend" />} />
            <Route path="recommend" element={<Recommend />} />
          </Route>
          <Route path="/results" element={<RecommendationResultsRouter />}>
            <Route path="" element={<RecommendationResults />} />
            <Route path=":resultId" element={<RecommendationResult />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Navigate to="/recommendations" />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Navigate to="/error?code=not_found" />} />
        </Routes>
      </Box>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <NotificationProvider>
        <AuthProvider>
          <PlayerProvider>
            <App />
          </PlayerProvider>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  );
}

export default AppWithRouter;
