import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
} from "@mui/material";
import RecommendationTracksSelectList from "../../components/RecommendationTracksSelectList/RecommendationTracksSelectList";
import {
  exportTracksCreatePlaylist,
  exportTracksExistingPlaylist,
  getUserOwnedPlaylists,
} from "../../services/Recommendations";
import { useNotification } from "../../context/NotificationProvider";
import { LoadingButton } from "@mui/lab";

const ExportSongsModal = ({ open, onClose, recommendations }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [createNewSelected, setCreateNewSelected] = useState(true);
  const [selectedTracks, setSelectedTracks] = useState([]);
  const [existingPlaylists, setExistingPlaylists] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const { addNotification } = useNotification();
  const steps = ["Choose Playlist", "Select Tracks"];

  useEffect(() => {
    if (recommendations?.tracks) {
      // Set selectedTracks to all tracks from recommendations
      setSelectedTracks(recommendations.tracks);
    } else {
      setSelectedTracks([]);
    }
  }, [recommendations]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const playlists = await getUserOwnedPlaylists();
        if (playlists) {
          setExistingPlaylists(playlists);
        }
      } catch (error) {
        console.error("Error fetching playlists:", error);
      }
    };

    fetchPlaylists();
  }, []);

  const handleNextStep = () => setActiveStep((prevStep) => prevStep + 1);
  const handlePreviousStep = () => {
    activeStep === 0 ? onClose() : setActiveStep((prevStep) => prevStep - 1);
  };
  const handleExport = async () => {
    setIsExporting(true); // Start exporting state

    try {
      // Extract track IDs from selected tracks
      const trackIds = selectedTracks.map((val) => val.trackData.id);

      // Validate input
      if (!trackIds || trackIds.length === 0) {
        throw new Error("No tracks selected for export.");
      }

      if (createNewSelected) {
        // Create a new playlist and add tracks
        const playlistId = await exportTracksCreatePlaylist(
          recommendations?.id, // Assuming `recommendations` contains recommendationResultId
          trackIds
        );
        addNotification({
          type: "success",
          content: `Playlist created successfully! Playlist ID: ${playlistId}`,
          id: `notif-create-success-${Date.now()}`,
          duration: 4000,
          position: { vertical: "bottom", horizontal: "center" },
        });
      } else if (selectedPlaylist) {
        // Add tracks to an existing playlist
        await exportTracksExistingPlaylist(selectedPlaylist.id, trackIds);
        addNotification({
          type: "success",
          content: `Tracks added to playlist: ${selectedPlaylist.name}`,
          id: `notif-add-success-${Date.now()}`,
          duration: 4000,
          position: { vertical: "bottom", horizontal: "center" },
        });
      } else {
        throw new Error("Please select a playlist or create a new one.");
      }
    } catch (error) {
      // Handle error
      addNotification({
        type: "error",
        content: "Failed to export tracks: " + (error.message || ""),
        id: `notif-error-${Date.now()}`,
        duration: 4000,
        position: { vertical: "bottom", horizontal: "center" },
      });
    } finally {
      setIsExporting(false); // End exporting state
      onClose();
    }
  };

  if (!recommendations) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      slotProps={{
        backdrop: {
          sx: { backgroundColor: "rgba(0, 0, 0, 0.80)" }, // Customize the backdrop
        },
      }}
    >
      <Box
        sx={{
          pt: 2,
          pb: 2,
          pl: 1,
          pr: 1,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DialogContent>
        <Box>
          {activeStep === 0 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Select an Existing Playlist or Create New
              </Typography>
              <RadioGroup
                value={createNewSelected}
                onChange={(e) =>
                  setCreateNewSelected(e.target.value === "true")
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Create New Playlist"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Choose Existing Playlist"
                />
              </RadioGroup>
              {!createNewSelected && (
                <Autocomplete
                  options={existingPlaylists}
                  getOptionLabel={(option) => option.name}
                  value={selectedPlaylist}
                  onChange={(event, newValue) => setSelectedPlaylist(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Playlist"
                      variant="outlined"
                    />
                  )}
                  sx={{ mt: 2 }}
                />
              )}
            </Box>
          )}
          {activeStep === 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Select Tracks to Export
              </Typography>
              <Box sx={{ width: "100%" }}>
                <RecommendationTracksSelectList
                  tracks={recommendations?.tracks}
                  selectedItems={selectedTracks}
                  onSelectChange={(updatedSelection) => {
                    setSelectedTracks(updatedSelection);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Box
        sx={{
          pt: 1,
          pb: 1,
          pl: 1,
          pr: 1,
          boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)", // Top shadow,
        }}
        display="flex"
        justifyContent="space-between"
      >
        <Button onClick={handlePreviousStep} variant="outlined">
          {activeStep === 0 ? "Cancel" : "Back"}
        </Button>
        {activeStep === steps.length - 1 ? (
          <LoadingButton
            onClick={handleExport}
            variant="contained"
            loading={isExporting}
            disabled={selectedTracks?.length === 0}
            color="primary"
          >
            Export
          </LoadingButton>
        ) : (
          <Button
            onClick={handleNextStep}
            variant="contained"
            disabled={!createNewSelected && !selectedPlaylist}
          >
            Next
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default ExportSongsModal;
