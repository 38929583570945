import React from "react";
import { Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

// Helper function to format timestamp to DD.MM.YYYY, HH:MM
const formatTimestamp = (date) => {
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
};

const RecommendationResultItem = ({ result }) => {
  const navigate = useNavigate(); // Initialize navigate for route navigation

  const handleClick = () => {
    navigate(`/results/${result.id}`);
  };

  // Set gradient background using emotionColor
  const gradientBackground = result.emotionColor
    ? `linear-gradient(180deg, ${result.emotionColor.primary}, ${result.emotionColor.secondary})`
    : "black"; // Fallback color if emotionColor is not provided

  return (
    <Paper
      elevation={3}
      sx={{
        marginBottom: "8px",
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        background: gradientBackground, // Use gradient background
        cursor: "pointer", // Make cursor pointer to indicate it's clickable
        padding: "16px",
        boxSizing: "border-box",
        "&:hover": {
          filter: "brightness(0.9)", // Slightly dim the card on hover
        },
        color: "white", // Ensure text is visible on gradient
      }}
      onClick={handleClick} // Handle click event
    >
      {/* Title */}
      <Typography
        variant="h6"
        sx={{ fontSize: "1.2rem", fontWeight: "bold", color: "white" }}
      >
        {result.title || "Untitled"}
      </Typography>

      {/* Vibe ID */}
      {result.vibeId && (
        <Typography variant="body2" sx={{ marginTop: "4px", color: "white" }}>
          Vibe ID: {result.vibeId}
        </Typography>
      )}

      {/* Time Created (Formatted) */}
      <Typography
        variant="body2"
        sx={{ marginTop: "8px", color: "rgba(255, 255, 255, 0.8)" }}
      >
        Created At: {formatTimestamp(result.timeCreated)}
      </Typography>

      {/* Liked Tracks Count */}
      <Typography
        variant="body2"
        sx={{ marginTop: "4px", color: "rgba(255, 255, 255, 0.8)" }}
      >
        Liked Tracks: {result.likedTracks ? result.likedTracks.size : 0}
      </Typography>
    </Paper>
  );
};

export default RecommendationResultItem;
