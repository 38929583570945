import React from "react";
import { Button } from "@mui/material";
import { IosShare } from "@mui/icons-material";

const ExportSongsButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<IosShare />}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      Export Songs
    </Button>
  );
};

export default ExportSongsButton;
