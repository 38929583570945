import { Typography, Card, CardContent } from "@mui/material";

const ClickedPointTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const emotion = payload[0].payload;
    return (
      <Card
        elevation={5}
        sx={(theme) => ({
          background: `${theme.palette.primary.main}`,
          color: "#fff",
          borderRadius: 2,
          boxShadow: 3,
        })}
      >
        <CardContent>
          {/* Emotion Name */}
          <Typography variant="h6" gutterBottom>
            {emotion.text}
          </Typography>

          {/* Valence and Arousal */}
          <Typography variant="body2">
            Valence: <strong>{emotion.valence.toFixed(2)}</strong>
          </Typography>
          <Typography variant="body2">
            Arousal: <strong>{emotion.arousal.toFixed(2)}</strong>
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default ClickedPointTooltip;
