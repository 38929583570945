import React from "react";
import { Box, Typography } from "@mui/material";
import RecommendationResultItem from "../RecommendationResultItem/RecommendationResultItem";

const RecommendationResultsList = ({ recommendationResults }) => {
  return recommendationResults && recommendationResults.length > 0 ? (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {recommendationResults.map((result) => (
        <RecommendationResultItem key={result.id} result={result} />
      ))}
    </Box>
  ) : (
    <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
      No recommendations available.
    </Typography>
  );
};

export default RecommendationResultsList;
