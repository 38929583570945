import RecommendationResultTrackDto from './RecommendationResultTrackDto';

class RecommendationDto {
    constructor(id, tracks, title, timeCreated, vibe, valence, arousal, userPrompt) {
        this.id = id;
        this.tracks = tracks;
        this.title = title;
        this.timeCreated = timeCreated;
        this.userPrompt = userPrompt
        this.vibe = vibe;
        this.valence = valence;
        this.arousal = arousal;
    }

    static fromJSON(data) {
        const tracks = data.tracks.map(RecommendationResultTrackDto.fromJSON);
        return new RecommendationDto(
            data.id,
            tracks,
            data.title,
            new Date(data.timeCreated),
            data.vibe,
            data.valence,
            data.arousal,
            data.userPrompt
        );
    }
}

export default RecommendationDto;
