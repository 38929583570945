import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#030303", // Black for primary elements like the login button
    },
    secondary: {
      main: "#cbcbcb", // Gray for secondary elements like the "New here? Join now" button
      contrastText: "#ffffff", // White text color for secondary buttons
    },
    background: {
      default: "#fff9f0", // Beige background color
      paper: "#fff9f0",
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
    h2: {
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 700, // Adjust as needed
      fontSize: "2rem", // Adjust as needed
      lineHeight: 1.2, // Adjust as needed
      letterSpacing: "0.02em", // Adjust as needed
    },
    h4: {
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 400, // Adjust as needed
      fontSize: "1.3rem", // Adjust as needed
      lineHeight: 1, // Adjust as needed
      letterSpacing: "0.01em", // Adjust as needed
    },
    h6: {
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 400, // Adjust as needed
      fontSize: "1.25rem", // Adjust as needed
      lineHeight: 1.4, // Adjust as needed
      letterSpacing: "0.01em", // Adjust as needed
    },
    button: {
      fontFamily: "Poppins, Arial, sans-serif",
      textTransform: "none", // Keeps button text casing as-is
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Adds a slight border radius to buttons
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
      <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
