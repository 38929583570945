import catImage from "../../../assets/keddy_error_no_bg.png";
import React, { useState, useEffect } from "react";
import "./Error.css";
import { useNavigate, useLocation } from "react-router-dom"; // Import the useNavigate hook
import { Typography } from "@mui/material";

function Error() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    // Extract query parameters from the location object
    const params = new URLSearchParams(location.search);
    const errorCode = params.get("code");

    // Set the error text based on the error code
    if (errorCode) {
      switch (errorCode) {
        case "invalid_auth_code":
          setErrorText("Invalid auth code.");
          break;
        case "invalid_state_code":
          setErrorText("Invalid state code.");
          break;
        case "code_state_not_provided":
          setErrorText("Spotify linking failed.");
          break;
        case "not_found":
          setErrorText("The page you requested could not be found.");
          break;
        default:
          setErrorText(
            "An unknown error occurred. Status Code:" + errorCode + "."
          );
      }
    }
  }, [location.search]);

  return (
    <div className="error__error-container">
      <div className="error__content">
        <img src={catImage} alt="Cat" className="error__cat-image" />
        <Typography fontSize={20} variant="h3">
          {errorText
            ? errorText + " Please contact dereoprojects@gmail.com for support."
            : "An unknown error occured."}
        </Typography>
        <Typography
          fontSize={13}
          variant="h3"
          marginTop={5}
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            color: "blue",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Return to main page
        </Typography>
      </div>
    </div>
  );
}

export default Error;
